<template>
  <div class="grid-side-content grid-content-wider">
    <ImgTag
      large
      :image="article.active_image?.url"
      :alt="article.title"
      class="col-span-5 h-full"
    />
    <div class="col-span-7 p-10">
      <div class="date pb-2.5 font-light">
        {{ formatDate(new Date(article.date), 'dd MMMM yyyy') }}
      </div>
      <h3 class="heading-sub lg:text-3xl">
        {{ article.title }}
      </h3>
      <SofieText
        class="mb-8 line-clamp-5"
        :value="article.text"
      />
      <NuxtLink
        class="text-[#849DBF]"
        :to="localePath({name: 'news-slug', params: {slug: article.slug}})"
      >
        {{ $t('Read message') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useLocaleDate} from '~/composables/useLocaleDate';

import type {Bericht} from '~/graphql/graphql';

defineProps<{
  article: Bericht
}>();

const formatDate = useLocaleDate();
</script>
